import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1>About</h1>
      <p>Born: December 2000</p>
      <Link to="/contact">More</Link>
    </Layout>
  );
};
export default AboutPage;
